import GET_CATEGORIES from "../plugins/getCategories"

const [ w, d, content ] = [ window, document, 'parts' ]

// 詳細ページにて実行
const detail_init = async post_id => {
  let articles = await $.getArticles({ content, post_status: 'all' })
  let categories = await $.getCategories({ content })
  let the_article = articles.filter( a => (a.id == post_id) ).shift()
  the_article.category = categories.filter( c => c.id == the_article.category )[0]

  // 詳細置き換え
  $('.element_detail').each( ( i, elm_detail ) => {
    let $head = $(`
      <div class="${content}-detail__head">
        ${the_article.category ? `<span class="cat">${the_article.category.name}</span>`: '' }
        <h3 class="title">${the_article.title}</h3>
      </div>
    `)
    $('.element_detail > .title, .detail_title').remove()

    $head.prependTo( elm_detail )

  })

  // カートに入れるボタンに[target="_blank"]追加
  $('.cartjs_box input[type="submit"]').on('click', function(){
    const $form = $( d.product_form )
    $form.attr('target' , '_blank')
  })

}

const shoppingGuide = (html,slot) => {
  
  if( slot ){
    $('.parts-guide [data-slot="guide"]').replaceWith( slot );
  }

  $('> ul > li, > [data-slot] > ul > li','.parts-guide__inner').each( function() {
    const $indicator = $(`<button class="button-text indicator">`)
    if( this.querySelectorAll('div').length ) {
      $('h4',this).append( $indicator )
    }
    $(this).children('div').slideUp();
    $indicator.on('click', ()=>{
      $(this).children('div').slideToggle()
      $indicator.toggleClass('is-active')
    })
  })
}


export default function PARTS () {

  // カテゴリ一覧ページにて、記事ごとの要素置き換え
  const replaceItemTemplate = item => `
    <a class="${content}-item" href="${item.href}">
      <figure class="${content}-item__img">
        <div class="${content}-item__img-cover" style="background-image:url( ${item.thumb} )">
          <img src="${item.thumb}" width="200">
        </div>
      </figure>
      <div class="${content}-item__body">
        ${item.cat ? `<span class="cate">${item.cat}</span>`: '' }
        <span class="subject">
          ${item.title}
        </span>
        <span class="desc">
          ${item.desc.str_truncate( 40 )}
        </span>
      </div>
      ${item.is_new ? '<span class="new">NEW</span>': ''}
    </a>
  `;

  const replace_item = () => {
    $('.contents .wrapLink','.element_list').each( (i,obj) => {
      let href = obj.getAttribute('href')
      let thumb = obj.querySelector('.thum_img > img').getAttribute('src')
      let is_new = obj.querySelectorAll('span.new').length > 0 ? true: false
      let catObj = obj.querySelector('.catetitle a')
      let cat = catObj? catObj.innerText : ''
      let dateObj = obj.querySelector('p.date')
      let date = dateObj? dateObj.innerText : false
      let title = obj.querySelector('h5 > a').getAttribute('title')
      let desc = obj.querySelector('p.catch').innerText
      $(obj).replaceWith( replaceItemTemplate({href,thumb,is_new,catObj,cat,dateObj,date,title,desc}) )
    })
  };
  
  $(() => {
    if( d.body.classList.contains('is--list') ) {
      $('.element_list .list_title1').remove()
      replace_item()
    }
    if( w.query_string('article') ) detail_init( w.query_string('article') )

    // カテゴリ一覧取得
    GET_CATEGORIES(`.${content}-catelist[data-plugin="getCategories"]`);

    $.when( w.load['shoppingGuide'] ).then( shoppingGuide );
  })
}