import VIEWPORTS from '../plugins/viewports'
import PARTSLOADER from '../plugins/partsloader'
import CONTENT_TITLE from '../plugins/contentTitle'
import SCROLL from '../modules/scroll'

// スクロールして画面エリアに入ったら要素を表示する lazydisp
import LAZYDISP from '../plugins/lazydisp'

// カレンダーカスタマイズ
import FULLCALENDAR from '../plugins/fullcalendar'

//pictureタグ使用時にIE11で画像サイズ指定
import IE_SRCSET from '../plugins/polyfill-ie-srcset'

// IE11でSVGの外部参照を有効にする
import 'svgxuse'

export default function INIT() {
  /*****
   * Promise
   */
  window.load = {
    'header': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'footer': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'mapScript': new $.Deferred, // googlemapAPIのURLパラメータでresolveします
    'loadHtml': new $.Deferred, // すべてのLoad Htmlが完了したらresolveする
  }

  //header.html, footer.html読み込み
  PARTSLOADER([
    {
      name: 'footer',
      loadhtml: '/asset/html/footer.html',
      target: 'footer',
      method:'replaceWith',
      callback(){
        $('footer [data-plugin="getCopyright"]').getCopyright()
        // スクロールイベント
        let $fixbtn = $('.p-footer-fixNav')
        SCROLL({
          scrollUp(){
            if( $fixbtn.hasClass('hidden-xsmall') && !$fixbtn.hasClass('is-intersect') ) $fixbtn.removeClass('hidden-xsmall')
          },
          scrollDown(){
          if( !$fixbtn.hasClass('hidden-xsmall') ) $fixbtn.addClass('hidden-xsmall')
          }
        })
      }
    },
    {
      name: 'header',
      loadhtml: '/asset/html/header.html',
      target: 'header',
      method:'replaceWith',
      callback(r,_){
        $('header .p-gnav li').currentMenu({item:'>a',mode:1,default:999});
        $('header .p-gnav li.is-inquiry').currentMenu({item:'>a',mode:0,default:999});
        $('header .p-gnav__subnav').currentMenu({mode:0,default:999});
        $('header .p-gnav__item').each( function(){
          const $indicator = $(`<a href="javascript:void(0);" class="p-gnav__item__indicator">`)
          if( this.querySelectorAll('.p-gnav__subnav-list').length ) {
            $(this).append( $indicator )
          }
          $(this).find('.p-gnav__subnav').slideUp();
          $indicator.on('click', ()=>{
            $(this).find('.p-gnav__subnav').slideToggle()
            $indicator.toggleClass('is-active')
          })
        })

        const mq = window.matchMedia( "(min-width: 900px)" )
        const $logos = $('.p-header__logo img[data-home-src]')
        const checkBreakpoint = (mq) => {
          if( location.pathname.replace(/(main|index)\.php/,'') === '/' && mq.matches ){
            $logos.each( (i,o) => $(o).attr({ 'src': o.dataset.homeSrc }) )
          }else{
            $logos.each( (i,o) => $(o).attr({ 'src': o.dataset.defaultSrc }) )
          }
        }
        mq.addListener( checkBreakpoint )
        checkBreakpoint(mq)
      }
    }
  ])
  // プライバシー・会社概要・サイトマップのコンテンツタイトル
  CONTENT_TITLE({
    'sitemap':'<span>SITEMAP</span><small>サイトマップ</small>',
    'privacy':'<span>PRIVACY POLICY</span><small>プライバシーポリシー</small>',
    'profile':'<span>SHOPINFO</span><small>店舗案内</small>'
  })

  //polyfill-ie-srcset
  IE_SRCSET()

  // DOM Ready
  $(() => {
    // <body>にクラス付与
    $.bodyClass();
    // htmlデータ読み込み
    $('[data-load-html]').loadHtml();
    // カレントメニュー
    $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});
    $.when( window.load['loadHtml'] ).then( () => {
      $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});
    });
    // ページングテキスト
    $.pagingText({
      prev:'PREV',
      next:'NEXT',
      list:'LIST'
    })
    // ページトップリンクをスクロール量で表示するサンプル
    $.scrollspy({
      trigger:200,
      onBefore(/* pos */) {
        $('.p-pagetop__wrap').addClass('hidden');
      },
      onAfter() {
        $('.p-pagetop__wrap').removeClass('hidden');
      }
    })

    // lazydisp
    $.when( window.load['loadHtml'] ).then( () => LAZYDISP('[data-lazydisp]') )
  })

  window.load['footer'].done(() => {
    // fullcalendar挿入
    FULLCALENDAR(document.querySelectorAll('[data-plugin="calendar"]'),{
      googleCalendarApiKey: 'AIzaSyCzR62G1w62iwwNPjvTaDqzIjaUXNcNUQg',
      contentHeight: 340,
      eventSources: [
        // {
        //   googleCalendarId: 'japanese__ja@holiday.calendar.google.com',
        //   className: 'holiday'
        // },
        {
          googleCalendarId: 'is@roadster.co.jp',
          className: 'event2'
        },
        {
          googleCalendarId: 'kaitori@roadster.co.jp',
          className: 'event3'
        },
        {
          googleCalendarId: 'roadster.co.jp_e8cphmdq0eem1tpuj4l8dprsho@group.calendar.google.com',
          className: 'event4'
        }
      ]
    })
  })

}