// import 'slick-carousel'
import GET_ARTICLES from '../plugins/getArticles'

const [ w, d, mq ] = [ window, document, window.matchMedia( "(max-width: 900px)" ) ]

const init = () => {



  $.when( w.load['header'] ).then( () => {
    const $logo = $('.p-header__logo img').hide()
    const $homeLogo = $('<img src="/asset/img/logo_white.png" alt="株式会社アイ・スタイリング" width="208">')

    $homeLogo.insertAfter( $logo )

    $.scrollspy({
      trigger: 10,
      onBefore(/* pos */) {
        if( mq.matches ) {
          $('.p-header').addClass('is-transparent')
          $logo.hide()
          $homeLogo.fadeIn()
        }
      },
      onAfter() {
        if( mq.matches ) {
          $('.p-header').removeClass('is-transparent')
          $logo.fadeIn()
          $homeLogo.hide()
        }
      }
    })
  })

  w.jQuery = $

  $.when(
    w.load['mainvisual'],
    $.getScript('/asset/js/vendor/jquery.mb.YTPlayer.min.js')
  ).then( () => {
    const $MV = $('[data-plugin="ytplayer"]')
    const param = id => `{
      videoURL: '${id}',
      containment: '.mainvisual__wrap',
      mute: true,
      showControls: false,
      autoPlay: true,
      useOnMobile: true,
      showYTLogo: false,
      abundance: '${(mq.matches)? 0.01: 0.07}',
      stopMovieOnBlur: false,
      loop: true,
      startAt: 0,
      anchor: 'center,center',
      quality: 'default'
    }`.replace(/\n|\s/g,'')
    $MV.attr('data-property',param('sKQsrlEm6-I'));
    $MV.YTPlayer()
    $MV.YTPPlay()
  })

    //ticker
    $.when( w.load['block-ticker'] ).then( () => {
      let makeItem = item => $(`
        <a href="${item.href}" class="link">
          <span class="cat">${item.category.name}</span>
          <span class="date">${item.date}</span>
          <span class="subject">${item.title}</span>
        </a>
      `)[0]
      GET_ARTICLES( '.block-ticker__article[data-plugin="getArticles"]', { makeItem })
    })

    // news
    $.when(w.load['block-shopinfo-news']).then( () => {
      let makeItem = item => $(`
      <li class="block-news-item">
        <a href="${item.href}">
          <figure class="block-news-item__img">
            <div class="block-news-item__img-cover" style="background-image:url(${item.thumb})"><img src="${item.thumb}" alt="${item.title}" width="220" loading="lazy"></div>
          </figure>
          <div class="block-news-item__body">
            <div class="subject">${item.title.str_truncate(24)}${item.is_new ? '<span class="is-new">NEW</span>': ''}</div>
            <span class="cate">${item.category.name}</span>
            <span class="date">${item.date}</span>
          </div>
        </a>
      </li>
      `)[0]
      GET_ARTICLES('.block-news__list[data-plugin="getArticles"]', { makeItem })
    })

}

export default function HOME() {
  $( init )
}