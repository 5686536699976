/*-----------------------------------
 * MP hayabusa Customize
 * [このファイルの役割]
 * ・プラグインの読み込み
 * ・URLによるモジュールの実行
 * ・サイト設定
 * ・URLによるCSS読み込み設定
 *-----------------------------------*/

import 'jquery.easing'
import './plugins/plugins'
import './plugins/utility'
import loader from './plugins/loader'
import INIT from './modules/init'

// ページ読み込み
import HOME from './page/home'
import SERVICE from './page/service'
import PARTS from './page/parts'
import SHOPINFO from './page/shopinfo'
import PROFILE from './page/profile'
import NEWS from './page/news'

/*****
 * サイト設定
 */
window.siteinfo = {
  mapApiKeys: {
    'local':          'AIzaSyCTB3MSMYkTjSz6l30k79XflmmL_FcOxkg', //development
    'www.example.jp': '' //production
  }
}

/*****
 * サイト初期化
 */
INIT()
loader(( request, path ) => {
  switch( path ){
    case '/': HOME(); break
    case '/service/': SERVICE(); break
    case '/parts/': PARTS(); break
    case '/shopinfo/': SHOPINFO(); break
    case '/profile.php': PROFILE(); break
    case '/news/': NEWS(); break
  }
  // switch( request ){}
})
