import 'intersection-observer' //polyfill

const [w, d, mq ] = [window, document, window.matchMedia('(max-width: 899px)')]
let methods

// スクロール方向の判定
const scrollDir = () => {
  let lastpos = 0
  let timer = null
  let FPS = 1000 / 60

  const scrollFunc = () => {
    clearTimeout(timer);
    timer = setTimeout(function() {
      if( !mq.matches ) return false
      let pos = w.pageYOffset || d.documentElement.scrollTop
      if( pos < lastpos ) methods('scrollUp')
      else methods('scrollDown')
      lastpos = pos
    }, FPS)
  }
  w.addEventListener('scroll', scrollFunc )
}

// スクロールの交差を監視
const scrollObserver = () => {
  const options = {
    root: null, // ビューポートをルート要素とする
    rootMargin: "20% 0px -20%", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  }
  const callback = (entries, object) => {
    entries.forEach( entry => {
      if (entry.isIntersecting) methods('intersectIn',[ entry, object ])
      else methods('intersectOut',[ entry, object ])
    })
  }
  const ovserver = new IntersectionObserver(callback,options)
  methods('intersectInit',[ ovserver ])
}


export default function SCROLL(methodsObj) {

  // スクロールによって実行する関数群
  methods = (name,args=[]) => {
    const methods = Object.assign({
      scrollUp(){
      },
      scrollDown(){
      },
      intersectInit ( ovserver ) {
      },
      intersectIn ( entry, object ) {
      },
      intersectOut ( entry, object ) {
      },
    }, methodsObj)

    if( ! ( name in methods ) ) console.error('%s method is not defined!', name)
    else methods[name](...args)
  }

  $(() => {
    scrollDir()
    scrollObserver()
  })
}

