const [w, d, l, content] = [window, document, location, 'service']


const list_init = () => {

  const getArticles = $.getJSON('/asset/api/getArticles/', {
    content,
    category_in: 1
  })

  //一覧ページカスタム
  getArticles.then((data) => {

    const $container = $('.element_list > .contents');
    $container.empty()

    $.each( data, ( i, item ) => {

      let $item = $(`
      <a href="${item.href}" class="${content}-item">
        <figure class="${content}-item__img">
          <div class="${content}-item__img-cover" style="background-image:url(${item.thumb})"><img src="${item.thumb}" alt="${item.title}" width=""></div>
        </figure>
        <div class="${content}-item__body">
          <h4 class="subject btn-border-bottom">
            <span class="title">${item.title}</span>
            <svg class="svgicon"><use xlink:href="/asset/svg/sprite.symbol.svg#arrow_right_circle" width="13" height="13"></use></svg>
          </h4>
          <p class="desc">${item.desc}</p>
        </div>
      </a>`);

      $container.append( $item )
    });
  });
}

const init = () => {

  //スロット適用 リフレッシュサービス
  $.when( w.load['service-01'] ).then( ( html, slot ) => {
    let $obj = $( '[data-slot="refresh-check-reserve"]' );
    slot ? $obj.replaceWith( slot ) : $obj.hide();
  });
  //スロット適用 中古車販売
  $.when( w.load['service-03'] ).then( ( html, slot ) => {
    let $obj = $( '[data-slot="usedcars-status"]' );
    slot ? $obj.replaceWith( slot ) : $obj.hide();
  });

  Promise.all( [ w.load['service-02'], w.load['service-03'], w.load['service-04'] ]).then( () => {
    $('.nav-service__wrap', '.block_head').prependTo('.service-head')
  });

}

const detail_init = async () => {
  const post_id = w.query_string('article');
  const articles = await $.getJSON('/asset/api/getArticles/', { content })
  const this_tags = await get_the_tags( post_id );
  const $container = $('<section class="service-related">');
  const relatedItems = relatedArticles( articles, post_id, this_tags, 4);
  const makeItem = item => {
    $(`<a href="${item.href}" class="service-related-item">
        <figure class="service-related-item__img">
          <div class="service-related-item__img-cover" style="background-image:url(${item.thumb})"><img src="${item.thumb}" width="222" /></div>
        </figure>
        <div class="service-related-item__body">
          <span class="title">${item.title.str_truncate( 20 )}</span>
        </div>
    </a>`)
    .appendTo( $container )
  }
  if( relatedItems.length ) {
    relatedItems.forEach( item => makeItem( item ) );
    $container.wrapInner('<div class="service-related__inner">').appendTo('.containerWrap');
    $('<h3 class="service-related__title">関連記事</h3>').prependTo( $container );
  }
}

const relatedArticles = (articles,post_id,this_tags,amount) => {
  return articles.filter( item => {
    if( item.id == post_id ) return false
    let related_tags = [...new Set( item.keywords )].filter( tag => {
      if( this_tags != null && this_tags.length ) return this_tags.includes( tag )
    });
    return related_tags && related_tags.length >= 1
  })
  .slice( 0, amount);
}


const get_the_tags = async ( post_id ) => {
  const getArticle = await $.getJSON('/asset/api/getArticles/',{
    content,
    post_id
  })
  return getArticle[0].keywords
}

export default function SERVICE() {
  $(init)
  d.addEventListener('DOMContentLoaded', () => {
    if( d.body.classList.contains('is--list') ) list_init();
    if( /article=\d+/.test( l.search ) ) detail_init();
  });
}