export default function GET_CATEGORIES(area='',option={}) {
  let $containers = $('[data-plugin="getCategories"][data-content]',area)

  if( $containers.length == 0 && $(area).data('plugin')=='getCategories' && $(area).data('content')!='' ){
    $containers = $(area)
  }else if($containers.length == 0){
    return false
  }
  const defarr = [] // containerごとのpromise格納
  $containers.each( (i,container) => {
    const opt = $.extend(true,{
      addAll: false,
      itemClass: '',
      allText: 'ALL',
      currentClass: 'current',
      makeItem: () => false
    },option,container.dataset)
    const d = new $.Deferred()
    defarr.push(d.promise())
    $.getJSON('/asset/api/getCategories/',{content: container.dataset.content}).done(list => {
      container.innerHTML = ''
      const $allLink = $(`<a href="/${container.dataset.content}/" class="${opt.itemClass}">${opt.allText}</a>`)
      if(opt.addAll) container.appendChild( $allLink[0] )
      $.each(list,(i,cat) => {
        if(cat.name == '') return false
        const item = opt.makeItem() ? opt.makeItem(cat,opt) : $(`<a href="/${container.dataset.content}/?mode=list&cat=${cat.id}" class="${opt.itemClass}">${cat.name}</a>`)[0]
        if( item ) container.appendChild( item )
      })
      $(container).currentMenu({mode:0,default:999})
      d.resolve(list)
    })
  })
  return $.when.apply($,defarr)
}