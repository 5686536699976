// プライバシー・会社概要・サイトマップのコンテンツタイトル
export default function CONTENT_TITLE(config){
  const page = location.pathname.match(/^\/([^/]+)\.php$/i)
  if(!page || !config[page[1]]) return false;
  $(() => {
    const $title = $(`<h2>${config[page[1]]}</h2>`),
          $container = $('<section class="mainImg"><div class="mainImgInner"></div></section>');
    $title.appendTo($container.find('.mainImgInner'));
    $container.insertAfter('#gmenu');
    $('.moduletitle').remove();
    var bodyClass = $('body').attr('class').replace(/[?|&]ipsw=\d/,'');
    $('body').attr('class',bodyClass);
  })
}