const [ w, d, l ] = [ window, document, location ]

const init = () => {

  $.getFreeBlock({ xml:'b_content_content5.xml', id:'7' }).then( blocks => {
    let $container = $('#container > #main', '.is--profile');
    $container.prepend( blocks );
    $('[data-load-html]').loadHtml();

    Promise.all( [ w.load['nav-shopinfo-head'] ] ).then(() => {
      $('[data-plugin=currentMenu]').currentMenu({ mode:0, default: 999 });
    });
  });

  $.getFreeBlock({ xml:'b_content_content5.xml', id:'6' }).then( blocks => {
    let $container = $('#container > #main', '.is--profile');
    $container.append( blocks );
    $('[data-load-html]').loadHtml();

    Promise.all( [ w.load['nav-shopinfo-foot'] ] ).then(() => {
      $('[data-plugin=currentMenu]').currentMenu({ mode:0, default: 999 });
    });
  });

}

export default function PROFILE () {
  $( init )
}