const [ w, d, l, content ] = [ window, document, location, 'news' ]

const detail_init = async (post_id) => {

  let this_article = await $.getJSON('/asset/api/getArticles/', { content, post_id } );
  let is_disp_date = await $.get('/hs_data/content6/conf.xml');
  is_disp_date = is_disp_date.getElementsByTagName('LIST_DISP_DATE')[0].textContent;
  this_article = this_article.shift();  //配列の0番目を取得

  if( this_article.date && is_disp_date !== 0 ){
    const $date = $(`<p class="date">${w.datetostr(this_article.date * 1000,'YYYY.MM.DD')}</p>`);
    $date.insertBefore('.hs_entrytitle');
  }

}

export default function NEWS () {
  if( w.query_string('article') || $('.element_detail').length != 0 ) {
    $( detail_init( w.query_string('article') ) );
  }
}