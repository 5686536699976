const [w, d, l] = [window, document, location]

const init = () => {

  Promise.all( [ w.load['shopinfo'] ]).then( () => {
    $('.shopinfo-nav', '.block_head').prependTo('.shopinfo-head')
  });

}

export default function SHOPINFO () {
  $( init )
}